<template>
  <div>
    <div class="title__section mb-3 text-center">
      Reporte del <span class="title__">{{ dateTimeInit }}</span> al
      <span class="title__">{{ dateTimeFin }}</span>
    </div>
    <div class="col-12">
      <div class="title__section text-left">
        Empresa: <span class="title__">{{ enterprise }}</span>
      </div>
    </div>
    <div class="col-12 mb-2">
      <div class="title__section text-left">
        Area: <span class="title__">{{ area }}</span>
      </div>
    </div>
    <div class="container__home" v-if="spinner">
      <div class="img__spinner">
        <img src="@/assets/img/LogoHelpSmart-01.png" alt="helpSmart" />
      </div>
    </div>
    <div v-else>
      <div v-if="hits.length > 0">
        <div class="col-12 mt-2">
          <div class="title__section text-center">Sensores</div>
          <div v-if="batteries.length > 0">
            <div
              class="batterry text-center mb-2"
              v-for="(item, index) in batteries"
              :key="index"
            >
              Batería: <i class="fas fa-battery-three-quarters"></i>
              {{ item.value + " " + item.unit }}
            </div>
          </div>
        </div>
        <div v-for="(item, index) in temperatures" :key="index">
          <graphic-temperature
            :item="item"
            @dateTimeSelected="dateTimeSelected"
          />
        </div>
        <div v-for="item in indicators" :key="item.name">
          <graphic-indicator :item="item" />
        </div>
        <div v-for="item in temperatureHumidities" :key="item.name">
          <graphic-temperature-and-humidity
            :item="item"
            @dateTimeSelected="dateTimeSelected"
          />
        </div>
      </div>
      <div v-else>
        <div class="container__home">
          <div class="title2 text-center">
            No hay sensores para mostrar en este rango de tiempo.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GraphicTemperature from "@/components/GraphicTemperature";
import GraphicIndicator from "@/components/GraphicIndicator";
import GraphicTemperatureAndHumidity from "@/components/GraphicTemperatureAndHumidity";

export default {
  name: "ReportGraphics",
  components: {
    GraphicTemperature,
    GraphicIndicator,
    GraphicTemperatureAndHumidity,
  },
  props: {
    batteries: {
      type: Array,
      default: () => [],
    },
    hits: {
      type: Array,
      default: () => [],
    },
    temperatures: {
      type: Array,
      default: () => [],
    },
    indicators: {
      type: Array,
      default: () => [],
    },
    temperatureHumidities: {
      type: Array,
      default: () => [],
    },
    dateTimeInit: {
      type: String,
      default: '',
    },
    dateTimeFin: {
      type: String,
      default: '',
    },
    enterprise: {
      type: String,
      default: '',
    },
    area: {
      type: String,
      default: '',
    },
    spinner: {
      type: Boolean,
      default: null,
    },
  },

  data() {
    const me = this;
    return {};
  },

  methods: {
    dateTimeSelected(datetime1, datetime2) {
      this.$emit('dateTimeSelected', datetime1, datetime2)
    },
  },
};
</script>

<style></style>
